import React from "react"
import axios from "axios"
import { FormattedMessage } from "react-intl"
import SubLayout from "../components/Sublayout"
import Button from "../components/Button"
import Container from "../components/structural/Container"
import { Mail } from "@material-ui/icons"

import styled from "styled-components"

const Card = styled.div`
  max-width: 900px;
  width: calc(90% - 32px);
  background-color: white;
  border-radius: 10px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  div {
    text-align: center;
    flex-grow: 1;
    width: 100%;
  }

  .divider {
    flex-grow: 0;
    margin: 16px;
    align-self: stretch;
    border: 1px solid rgba(0, 0, 0, 0.15);
    @media (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;
    }
    width: auto;
  }

  .form-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;

    textarea {
      min-height: 200px;
    }
  }

  .left-text {
    align-self: flex-start;
    margin-top: 64px;
    @media (max-width: 768px) {
      margin-top: 0px;
    }

    div {
      display: flex;
      justify-content: center;
      svg {
        margin-right: 12px;
      }
    }
  }

  .form-wrap button {
    margin-top: 32px;
    align-self: flex-end;
    height: 42px;
    width: 160px;
  }
`

class Contact extends React.Component {
  state = { senderName: "", senderMail: "", message: "" }

  handleChange = (e) => {
    let key = e.target.name
    let value = e.target.value
    this.setState({ [key]: value })
  }

  submit = (e) => {
    let body = {
      sender: this.state.senderMail,
      name: this.state.senderName,
      message: this.state.message,
    }
    axios
      .post(
        "https://7hu5oo591e.execute-api.eu-central-1.amazonaws.com/default/kanyaContactMail",
        body
      )
      .then((res) => {
        alert("Sent Message")
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data.error)
        } else {
          alert("An unknown error occured")
        }
      })
  }

  render() {
    const { senderName, senderMail, message } = this.state

    return (
      <SubLayout fixedHeader>
        <Container heading="Contact.heading">
          <Card>
            <div className="left-text">
              <FormattedMessage id="Contact.heading" tagName="h1" />
              <FormattedMessage id="Contact.mail.p" tagName="p" />
              <div>
                <Mail></Mail>
                <a href="mailto:info@kanyaschance.org">info@kanyaschance.org</a>
              </div>
            </div>
            <div className="divider"></div>
            <div className="form-wrap">
              <input
                type="text"
                onChange={this.handleChange}
                name="senderName"
                value={senderName}
                placeholder="Name"
              />
              <input
                type="text"
                onChange={this.handleChange}
                name="senderMail"
                value={senderMail}
                placeholder="Mail"
              />
              <textarea
                type="textarea"
                onChange={this.handleChange}
                name="message"
                value={message}
                placeholder="Your Message"
              />
              <Button onClick={this.submit}>
                <FormattedMessage id="Runform.button.submit" />
              </Button>
            </div>
          </Card>
        </Container>
      </SubLayout>
    )
  }
}

export default Contact
